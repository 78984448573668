// 외부모듈
import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import { css } from "@emotion/core"

// 리소스
//@ts-ignore
import check from "../images/check.gif"
//@ts-ignore
import exclamation from "../images/exclamation.gif"
import xmark from "../images/x-mark.png"

const API =
  "https://us-central1-planz-6761f.cloudfunctions.net/creditCardRefund"

enum Status {
  loading = "요청을 처리하는 중입니다.",
  success = "요청을 완료하였습니다.",
  failed = "요청에 실패하였습니다.",
  already = "이미 요청을 완료한 작업입니다.",
  wrong = "잘못된 경로입니다.",
}

function CreditCardRefund({ location }: any) {
  const [status, setStatus] = useState<Status>(Status.loading)
  const data = location.search.slice(6)

  useEffect(() => {
    try {
      if (location.search === "") {
        setStatus(Status.wrong)
      } else {
        axios.post(API, { data }).then(res => {
          if (res.status === 200) {
            setStatus(Status.success)
          } else if (res.status === 208) setStatus(Status.already)
          else setStatus(Status.failed)
        })
      }
    } catch (error) {
      setStatus(Status.failed)
    }
  }, [])

  const renderPage = useCallback((status: Status) => {
    switch (status) {
      case Status.loading:
        return (
          <>
            <CircularProgress size={60} css={{ marginBottom: "15px" }} />
            <p>{Status.loading}</p>
            <p>페이지를 닫지 말아주세요</p>
          </>
        )
      case Status.success:
        return (
          <>
            <img src={check} css={check__style}></img>
            <p>{Status.success}</p>
          </>
        )
      case Status.failed:
        return (
          <>
            <img src={exclamation} css={check__style}></img>
            <p>{Status.failed}</p>
          </>
        )
      case Status.already:
        return (
          <>
            <img src={exclamation} css={check__style}></img>
            <p>{Status.already}</p>
          </>
        )
      default:
        return (
          <>
            <img src={xmark} css={xmark__style}></img>
            <p>잘못된 경로입니다.</p>
          </>
        )
    }
  }, [])

  return (
    <section css={container}>
      <div css={content}>{renderPage(status)}</div>
    </section>
  )
}

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
`

const content = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const check__style = css`
  width: 200px;
  height: 160px;
`

const xmark__style = css`
  width: 120;
  height: 120px;
`

export default CreditCardRefund
